// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bJ_k3";
export var caseStudyBackground__bgColor = "bJ_k5";
export var caseStudyBackground__lineColor = "bJ_k0";
export var caseStudyHead__imageAbsolute = "bJ_lc";
export var caseStudyHead__imageWrapper = "bJ_kX";
export var caseStudyProjectsSection = "bJ_k4";
export var caseStudyQuote__bgRing = "bJ_kZ";
export var caseStudyTech = "bJ_lb";
export var caseStudyVideo = "bJ_k1";
export var caseStudyVideo__ring = "bJ_k2";
export var caseStudy__bgDark = "bJ_kV";
export var caseStudy__bgLight = "bJ_kT";